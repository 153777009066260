<template>
  <div>
    <el-row type="flex" align="middle">
      <el-col>
        <h3>Edit {{ company.human_name }}</h3>
      </el-col>
    </el-row>

    <el-row class="company-form">
      <el-col>
        <company-form
          ref="base-company-form"
          :form-data="form"
          :loading="saving"
          @save="update()"
        />
      </el-col>
    </el-row>
  </div>
</template>

<script>
import BaseCompanyForm from './_components/forms/BaseCompanyForm'

import RooofAPI from '@/services/api/rooof'
import CraigslistAPI from '@/services/api/craigslist'

export default {
  name: 'CompanyEdit',
  components: {
    'company-form': BaseCompanyForm
  },
  props: {
    company: {
      type: Object,
      required: true
    },
    loadingCompany: {
      type: Boolean,
      required: true
    }
  },
  data () {
    return {
      form: JSON.parse(JSON.stringify(this.company)), // copy data
      saving: false
    }
  },
  beforeRouteLeave (to, from, next) {
    if (this.$refs['base-company-form'] && this.$refs['base-company-form'].dataModified) {
      const answer = window.confirm('Form data has been modified without saving. Continue?')
      if (!answer) {
        next(false)
        return
      }
    }
    next()
  },
  methods: {
    /**
     * Calls API to update a Company.
     */
    async update () {
      try {
        this.saving = true

        // Make a copy so we can do some preliminary serialization
        const companyData = JSON.parse(JSON.stringify(this.form))

        // Remove the agreement file (must choose either json or form-data for
        // the payload, not both - we'll update the company after)
        delete companyData.agreements

        // Update the Company
        const promises = [ RooofAPI.companies.update(companyData.id, companyData) ]

        const config = this.form.clientConfig
        if (config) {
          promises.push(RooofAPI.config.partialUpdate(companyData.id, config))
        }

        const photos = this.form.photos
        if (photos) {
          for (const photo of photos.added) {
            const formData = new FormData()
            formData.append('file', photo.raw)
            promises.push(CraigslistAPI.syndication_photos.create(companyData.id, 'groups', formData))
          }
          for (const photo of photos.removed) {
            promises.push(CraigslistAPI.syndication_photos.delete(companyData.id, 'groups', photo.id))
          }
          this.$delete(this.form, 'photos')
        }

        await Promise.all(promises)

        // Now upload the agreement (if it exists)
        if (this.form.agreements) {
          for (const agreement of this.form.agreements.added) {
            const formData = new FormData()
            formData.append('file', agreement.raw)
            promises.push(RooofAPI.agreements.create(companyData.id, 'companies', formData))
          }
          for (const agreement of this.form.agreements.removed) {
            promises.push(RooofAPI.agreements.delete(companyData.id, 'companies', agreement.id))
          }
          this.$delete(this.form, 'agreements')
        }

        await Promise.all(promises)

        // Update cached data
        this.$store.dispatch('fetch_company_list')
        this.$emit('refresh')

        this.$message({
          message: `Company ${this.form.human_name} updated`,
          type: 'success',
          duration: 3500
        })
        this.$router.push({ name: 'CompanyOverview' })
      } catch (err) {
        const details = err.response ? err.response.data : null
        this.$rfAlert.error(this, err.toString(), details)
      } finally {
        this.saving = false
      }
    }
  }
}
</script>

<style scoped>
.company-form {
  margin-top: 1em;
}
</style>
